import React, { Component, useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { PasswordReg, WebStorageNames } from '../../../utils';
import { ExtendedWrapperMain } from '../../../resources/styling/formStyle';
import { toast } from 'react-toastify';
import {
  ErrorWrapper,
  FieldWrapper,
  AppButton,
} from '../../../resources/styling';
import { WelbeeTextField } from '../../../resources/styling/appStyle';
import { colors } from '../../../resources/theme/colors';
import {
  schoolService,
  accountService,
  storageService,
  matGroupService,
} from '../../../services';
//import { loaderAction } from '../../../store/loaderStore';
import { PasswordViewIcon } from '../../../components/common/loginStyle';
import { loginNormalEye, loginShowEye } from '../../../resources/images/index';
import Grid from '@mui/material/Grid';
//import { amplitudeLog } from '../../../utils/CommonFunctions';

const { mandy } = colors;

export default function MatAdminInfoForm(props) {
  const [MatRecord, SetMatRecord] = useState({});
  const [matLogoUrl, setMatLogoUrl] = useState('');
  const [isLogoUpdated, setIsLogoUpdated] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(true);
  const userEmail = storageService.getItem(WebStorageNames.UserInfo)?.Email;
  const userRole = storageService.getItem(WebStorageNames.Role);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState('');

  const [groupDirectorData, setGroupDirectorData] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });

  const getGroupDirectorData = () => {
    const userId = storageService.getItem(WebStorageNames.UserInfo)?.Id;
    // const userEmail = storageService.getItem(WebStorageNames.UserInfo)?.Email;
    // const userRole = storageService.getItem(WebStorageNames.Role);
    const user = props.MatRecord?.GroupDirectors?.find((x) => {
      return (x) => x.UserId === userId;
    })?.User;
    if (user) {
      const { Email, FirstName, LastName } = user;
      // console.log('Account GD email', Email);
      // console.log('Logged  in email', userEmail);
      // console.log('Logged  in Role', userRole);
      const obj = {
        email: Email,
        firstName: FirstName,
        lastName: LastName,
      };
      setGroupDirectorData(obj);
    }
  };

  useEffect(() => {
    const { MatRecord } = props;
    if (MatRecord) {
      SetMatRecord(MatRecord);
    }
    getGroupDirectorData();
  }, [props.MatRecord]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        Id: MatRecord?.Id || 0,
        GroupName: MatRecord?.GroupName || 'MAT',
        Name: MatRecord?.Name || '',
        UserName: MatRecord?.Username || '',
        Email: groupDirectorData.email || '',
        FirstName: groupDirectorData.firstName || '',
        LastName: groupDirectorData.lastName || '',
      }}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={(values) => {
        console.log(values);
        //const { loaderActions } = props;
        matGroupService
          .updateMatUsers(values)
          .then((response) => {
            if (response.success) {
              toast.success(
                'Account information has been updated successfully.'
              );
              if (values.File) {
                const logo = `${
                  MatRecord.Logo.FileUrl.split('?')[0]
                }?fileName=${response.data}`;
                setMatLogoUrl(logo);
                setIsLogoUpdated(true);

                storageService.setItem(WebStorageNames.matLogoUrl, logo);
                window.location.reload();
                props.updateData(logo);
              } else {
                window.location.reload();
              }
            } else {
              toast.warning(response.message);
            }
          })
          .catch((error) => {});
      }}
      render={({
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <div className='search-inner-sub-container'>
          <h2> Account Information</h2>

          <div className='form-body'>
            {values.Email===userEmail || userRole=== 'SuperAdmin' ? (   
              <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <ExtendedWrapperMain>
                  <WelbeeTextField
                    fullWidth
                    label='First Name'
                    name='First name'
                    value={values.FirstName}
                    onChange={(e) => {
                      setFieldValue('FirstName', e.target.value);
                      setFieldValue('IsUpdateEnabled', true);
                    }}
                  />
                  <ErrorWrapper>
                    {touched.FirstName && errors.FirstName}
                  </ErrorWrapper>
                </ExtendedWrapperMain>
              </Grid>
              <Grid item md={6} xs={12}>
                <ExtendedWrapperMain>
                  <WelbeeTextField
                    fullWidth
                    label='Last Name'
                    name='Last name'
                    value={values.LastName}
                    onChange={(e) => {
                      setFieldValue('LastName', e.target.value);
                      setFieldValue('IsUpdateEnabled', true);
                    }}
                  />
                  <ErrorWrapper>
                    {touched.LastName && errors.LastName}
                  </ErrorWrapper>
                </ExtendedWrapperMain>
              </Grid>
              <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
                <ExtendedWrapperMain>
                  <WelbeeTextField
                    fullWidth
                    label='Email'
                    name='Email'
                    value={values.Email}
                    onChange={(e) => {
                      setFieldValue('Email', e.target.value);
                      setFieldValue('IsUpdateEnabled', true);
                    }}
                  />
                  <ErrorWrapper>{touched.Email && errors.Email}</ErrorWrapper>
                </ExtendedWrapperMain>
              </Grid>                            
              <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                <ExtendedWrapperMain>
                  <WelbeeTextField
                    fullWidth
                    label='Password'
                    name='Password'
                    type={isPasswordShow ? 'password' : 'text'}
                    autoComplete='new-password'
                    value={values.Password}
                    onChange={(e) => {
                      setFieldValue('Password', e.target.value);
                      setFieldValue('IsUpdateEnabled', true);

                      setConfirmPasswordErrorMessage('');
                    }}
                    onBlur={handleBlur}
                  />
                  <ErrorWrapper>{errors.Password}</ErrorWrapper>
                  {/* <button className='change-button'>Change</button> */}
                  <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                    <img
                      src={isPasswordShow ? loginNormalEye : loginShowEye}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsPasswordShow(!isPasswordShow);
                      }}
                      alt=''
                    />
                  </PasswordViewIcon>
                </ExtendedWrapperMain>
              </Grid>
              <Grid item md={6} xs={12} style={{ paddingTop: '0' }}>
                <ExtendedWrapperMain>
                  <WelbeeTextField
                    fullWidth
                    label='Confirm Password'
                    name='Confirm Password'
                    type={isPasswordShow ? 'password' : 'text'}
                    autoComplete='new-password'
                    value={values.ConfirmPassword}
                    onChange={(e) => {
                      setFieldValue('ConfirmPassword', e.target.value);
                      setFieldValue('IsUpdateEnabled', true);
                      setConfirmPasswordErrorMessage('');
                    }}
                  />

                  {/* <button className='change-button'>Change</button> */}
                  <PasswordViewIcon onClick={(e) => e.preventDefault()}>
                    <img
                      src={isPasswordShow ? loginNormalEye : loginShowEye}
                      onClick={(e) => {
                        e.preventDefault();

                        setIsPasswordShow(!isPasswordShow);
                      }}
                      alt=''
                    />
                  </PasswordViewIcon>
                  <ErrorWrapper>{confirmPasswordErrorMessage}</ErrorWrapper>
                </ExtendedWrapperMain>
              </Grid>
              <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
                <p className='password-instructions'>
                  To change your password please type into the box above and
                  click update. Passwords need to be between 8 and 50 characters
                  and contain at least one uppercase, lowercase, number and
                  special character.
                </p>
              </Grid>
              <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
                <ExtendedWrapperMain>
                  <FieldWrapper>
                    <AppButton
                      color={values.IsUpdateEnabled ? mandy : ''}
                      type='submit'
                      onClick={values.IsUpdateEnabled ? handleSubmit : {}}
                    >
                      Update
                    </AppButton>
                  </FieldWrapper>
                </ExtendedWrapperMain>
              </Grid>                         
              </Grid>                                
            ) : (
            <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <ExtendedWrapperMain>
              <WelbeeTextField
                fullWidth
                label='First Name'
                name='First name'
                disabled='true'
                value={values.FirstName}
                onChange={(e) => {
                  setFieldValue('FirstName', e.target.value);
                  setFieldValue('IsUpdateEnabled', true);
                }}
              />
              <ErrorWrapper>
                {touched.FirstName && errors.FirstName}
              </ErrorWrapper>
            </ExtendedWrapperMain>
          </Grid>
          <Grid item md={6} xs={12}>
            <ExtendedWrapperMain>
              <WelbeeTextField
                fullWidth
                label='Last Name'
                name='Last name'
                disabled='true'
                value={values.LastName}
                onChange={(e) => {
                  setFieldValue('LastName', e.target.value);
                  setFieldValue('IsUpdateEnabled', true);
                }}
              />
              <ErrorWrapper>
                {touched.LastName && errors.LastName}
              </ErrorWrapper>
            </ExtendedWrapperMain>
          </Grid>
          <Grid item md={12} xs={12} style={{ paddingTop: '0' }}>
            <ExtendedWrapperMain>
              <WelbeeTextField
                fullWidth
                label='Email'
                name='Email'
                disabled='true'
                value={values.Email}
                onChange={(e) => {
                  setFieldValue('Email', e.target.value);
                  setFieldValue('IsUpdateEnabled', true);
                }}
              />
              <ErrorWrapper>{touched.Email && errors.Email}</ErrorWrapper>
            </ExtendedWrapperMain>
          </Grid>                                                                               
            </Grid>
            )}     
          </div>
        </div>
      )}
    ></Formik>
  );
}
